'use client';

import { useParams } from 'next/navigation';
import pt from '../dictionaries/ptBR.json';
import en from '../dictionaries/en.json';
import es from '../dictionaries/es.json';
import cn from '../dictionaries/cn.json';

const dictionaries = {
  'pt-BR': pt,
  'en-US': en,
  'zh-CN': cn,
  'es-ES': es,
};

export function useTranslations() {
  const params = useParams();
  const locale = (params?.lang as string) || 'pt-BR';

  return dictionaries[locale as keyof typeof dictionaries];
}

export function useFormatDate() {
  const params = useParams();
  const locale = (params?.lang as string) || 'pt-BR';

  return (date: string) => {
    return new Date(date).toLocaleDateString(locale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };
}
